<template>
  <div class="w-dvw h-dvh flex justify-center items-center py-4 px-6">
    <div class="flex flex-col gap-5 items-center w-full sm:max-w-[360px]">
      <div class="flex flex-col items-center gap-3">
        <img
          alt="Robotkong Booking System Logo"
          class="h-12 w-12"
          src="../assets/logos/robotkonglogo.svg"
        />
        <h1 class="font-semibold text-2xl">Welcome back!</h1>
      </div>

      <div class="w-full flex gap-4 flex-col">
        <Input
          required
          placeholder="you@company.com"
          v-model="v$.email.$model"
          :errors="v$.email.$errors"
          autocomplete="off"
        >
          <template #label>Email</template>
          <template #icon-right>
            <EnvelopeIcon class="h-4 inline" />
          </template>
        </Input>
        <Input
          required
          placeholder="********"
          type="password"
          v-model="v$.password.$model"
          :errors="v$.password.$errors"
          autocomplete="off"
        >
          <template #label>Password</template>
          <template #icon-right>
            <LockClosedIcon class="h-4 inline" />
          </template>
        </Input>
      </div>

      <div class="flex w-full flex-col items-center gap-5">
        <Button
          class="w-full"
          variant="branded"
          size="lg"
          :working="working"
          @click="handleSubmitFirst"
        >
          Sign in
        </Button>
        <div class="flex w-full justify-between text-sm">
          <span class="flex gap-3 items-center"
            ><a href="/" class="text-[#CF3C1B] flex gap-2 items-center"
              ><ArrowLeftIcon class="h-4 inline" /> Website</a
            ></span
          >
          <span class="flex gap-3 items-center"
            >No account?
            <a href="/register" class="text-[#CF3C1B] flex gap-2 items-center"
              >Sign up <ArrowRightIcon class="h-4 inline"
            /></a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { toast } from 'vue-sonner'
import {
  EnvelopeIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  LockClosedIcon,
} from '@heroicons/vue/24/outline'
import Input from '~/components/form/Input.vue'
import { helpers, required as requiredValidator } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

definePageMeta({
  layout: false,
})
const client = useSupabaseClient()
await client.auth.signOut()

const working = ref(false)
const email = ref('')
const password = ref('')

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(
        'Please add your email address',
        requiredValidator,
      ),
    },
    password: {
      required: helpers.withMessage(
        'Please add your password',
        requiredValidator,
      ),
    },
  }
})

const v$ = useVuelidate(rules, {
  email,
  password,
})

const user = useSupabaseUser()
const handleSubmitFirst = async () => {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return false
  working.value = true
  const { user, session, error } = await client.auth.signInWithPassword({
    email: email.value,
    password: password.value,
  })
  if (error) {
    toast.error('Error', {
      description:
        error.message || 'An error occurred when creating your account',
    })
    working.value = false
  }
}

watch(user, async (newUser) => {
  if (newUser?.id) {
    await navigateTo('/')
  }
})
</script>
